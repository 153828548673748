// -------------------------
// Alias
// -------------------------

@alias {
	ff: font-family;
	fs: font-size;
	fw: font-weight;
	lh: line-height;
}
