html, body, *
  font-family: 'FrutigerNextW02-Regular 724926';
  box-sizing border-box

.hidden-paths
  display none;

.disable-scroll
  overflow hidden

.deactivating-overlay
  display none
  width 100%
  height 100vh
  position fixed
  top 0
  left 0
  background-color rgba(0, 0, 0, .5)

.chosen
  border 4px solid main-color-1 !important
  box-sizing border-box !important

.text-left
  text-align left !important

.no-margin-bottom
  margin-bottom 0 !important

.upload-button
  &:hover
    cursor pointer

.dn
  display none !important

header
  lost-column 1/1
  height 140px
  .logo-container
    width 100%
    height 100%
    text-align left
    padding 0 20px
    box-sizing border-box
    img
      height 85px
      margin-top 25px
    h1
      display inline-block
      height 75px
      line-height 35px
      font-family open_sanslight
      font-weight normal
      position absolute
      top 55px
      left 125px
      color logo-text-color
      font-size xlarge-font-s
      margin 0
  .menu-opener
    position absolute
    right 25px
    top 20px
    width 30px
  nav
    position absolute
    top 20px
    width calc(100% - 30px)
    left 15px
    box-sizing border-box
    display none
    z-index 1
    .mobile-navi-container
      position relative
      background-color mobile-nav-bg-color
      height 100%
      .cross
        display inline-block
        position absolute
        right 20px
        top 25px
        &:hover
          cursor pointer
      ul
        padding 17% 20%
        font-size 0
        li
          display inline-block
          width 100%
          height 65px
          line-height 65px
          font-size medium-font-l
          border-top 1px solid mobile-nav-border-color
          &:last-child
            border-bottom 1px solid mobile-nav-border-color
        a
          display inline-block
          height 100%
          width 100%
          padding-left 40px
          box-sizing border-box
        a:link, a:visited, a:hover, a:active
          color dark-color
        li.report
          background url("../images/report-icon.png") no-repeat left center
        li.protect
          background url("../images/protect-icon.png") no-repeat left center
        li.search
          background url("../images/search-icon.png") no-repeat left center
        li.mydata
          background url("../images/mydata-icon.png") no-repeat left center
        li.contact
          background url("../images/contact-icon.png") no-repeat left center
        li.imprint
          background url("../images/imprint-icon.png") no-repeat left center




main
  lost-column 1/1
  width 100%
  min-height calc(100vh - 185px)
  background-image url('../images/background.png')
  background-size cover
  .content
    box-sizing border-box
    lost-column 1/1
    width 100%
    padding 0 20px
    padding-top 50px
    .link-container
      lost-column 1/1
      height 90px
      background-color rgba(0, 0, 0, .5)
      margin-bottom 30px
      a
        lost-column 1/1
        display table

        width 100%
        height 100%
        box-sizing border-box
        padding 0 15px
        .image-container
          display table-cell
          margin-top 20px
          width 30px
          vertical-align middle
          img
            width 100%
            margin-top 5px
        .text
          display table-cell
          vertical-align middle
          box-sizing border-box
          padding-left 20px
        &:link, &:visited, &:hover, &:active
          color light-color
          font-size medium-font
    .link-container.report-damage
      height 150px
      a
        .image-container
          width 71px
        &:link, &:visited, &:hover, &:active
          font-size medium-font-l
    .link-container.report-damage.mode
      a
        .image-container
          width 35px
        &:link, &:visited, &:hover, &:active
          font-size medium-font-l
    .button-container
      lost-column 1/1
      text-align center
      margin-bottom 50px
      .btn
        display inline-block
        width 80%
        height 50px
        box-sizing border-box
        text-align center
        line-height 50px
        background-color single-button-bg-color
        color dark-color
        font-family opensans_regular
        font-weight 600
        border-radius 25px
        text-transform uppercase
        font-size large-font
    .customer-container
      lost-column 1/1
      text-align center
      padding 0 20px
      margin-bottom 20px
      img
        width 100%
    .desc-line
      lost-column 1/1
      width 100%
      background-color desc-line-bg-color
      padding 5px 0
      position relative
      font-family open_sanssemibold
      text-align center
      margin-bottom 30px
      line-height 27px
      &:before, &:after
        content ''
        position absolute
        width 20px
        height 100%
        background-color desc-line-bg-color
        top 0
      &:before
        left -20px
      &:after
        right -20px
      .info-text-container
        font-weight 600
    .desc-line.map-desc
      padding 20px 0
      box-sizing border-box
    .desc-line.headline
      font-family opensans_regular
      font-size large-font
      color label-text-color


  .choice-wrapper
    lost-column 1/1
    .link-button
      lost-column 1/2 2 15px
      text-align center
      background-color rgba(0, 0, 0, .5)
      margin-bottom 20px
      a
        display inline-block
        width 100%
        height 100%
        padding-top 10px
        padding-bottom 0
        box-sizing border-box
        font-size medium-font
      a:link, a:visited, a:link, a:active
        color light-color

      .image-container
        lost-column 1/1
        img
          height 55px
      .text-container
        lost-column 1/1

  .form-element
    lost-column 1/1
    width 100%
    .form-elements-container
      width 100%
      .input-wrapper
        lost-column 1/1
        width 100%
        background-color primary-background-color
        box-sizing border-box
        padding-bottom 20px
        position relative
        &:first-child
          box-sizing border-box
          padding-top 30px
        &:before, &:after
          content ''
          position absolute
          height 100%
          background-color desc-line-bg-color
          top 0
        &:before
          width 20px
          left -20px
        &:after
          width 20px
          right -20px
        label, input
          background-color light-color
          box-sizing border-box
          outline 0
        label
          lost-column 6/13 0 0
          height 40px
          line-height 40px
          border 1px solid elements-border-color
          border-top-left-radius 20px
          border-bottom-left-radius 20px
          border-right-color desc-line-bg-color
          text-indent 20px
          color label-text-color
          font-family open_sansbold
          font-size small-font
          overflow hidden
        label.explain
          lost-column 1/1
          text-align center
          background-color transparent
          border none
          font-size xlarge-font-s
          font-family opensans_regular
          margin-bottom 15px
          text-indent 0
        input.text-box
          lost-column 1/1
          height 250px
          overflow auto
          border-radius 26px
          border 1px solid elements-border-color
        input, select
          lost-column 7/13 0 0
          height 40px
          line-height 40px
          border 1px solid elements-border-color
          border-radius 0
          border-top-right-radius 20px
          border-bottom-right-radius 20px
          border-left none
          font-size small-font
        select
          outline 0
        input
          text-indent 5px
        input[type=radio]
          border none
          background-color transparent
        .textarea-container
          width 100%
          min-height 150px
          line-height 17px
          border 1px solid elements-border-color
          border-radius 20px
          font-size small-font

          padding 20px
          background-color light-color
          textarea
            //border-bottom-right-radius 0px
            //border-top-right-radius 0px
            width 100%
            min-height 150px
            border none
            overflow-x hidden
            overflow-y auto
            resize none
            outline none


        .radio-container
          lost-column 7/13 0 0
          border-radius 0
          border 1px solid elements-border-color
          border-top-right-radius 20px
          border-bottom-right-radius 20px
          border-left none
          ul
            display table
            box-sizing border-box
            width 100%
            height 38px
            li
              display table-cell
              box-sizing border-box
              padding-left 20px
              vertical-align middle
              .radio-wrapper
                display inline-block
                input[type=radio]
                  height auto
              .txt
                display inline-block
                padding-left 4px
                font-size medium-font
        input.single-input
          border none
          lost-column 1/1
          border-radius 0
        .button-wrapper
          lost-column 1/1
          text-align center
          margin-top 20px
          height 40px
          .pic-button, .audio-button, button, input[type=submit]
            position relative
            background-color light-color
            border 1px solid elements-border-color
            height 40px
            line-height 40px
            width 100%
            border-radius 20px
            font-size medium-font
            text-align left
            text-indent 35px
            color label-text-color
            img
             position absolute
             top 10px
             left 15px
             display inline-block
             width 14px
          input[type=submit]
            text-indent 0
            text-align center
            background-color main-color-1
            font-size medium-font-l
            text-transform uppercase
            border none
            color dark-color
            font-family open_sansbold
        .button-wrapper.normal
          input[type=submit]
            background-color main-submit-button-color
            color light-color
        .headline-element
          color label-text-color
          font-family opensans_regular
          font-weight normal
          text-align center
          font-size xlarge-font-s
        .info_icon_message
          position relative
          lost-column 1/1
          box-sizing border-box
          margin-top 5px
          color label-text-color
          font-size medium-font
          i
            &:hover
              cursor pointer
          .info-box
            position absolute
            top 0
            left 15px
            display inline-block
            width 200px
            height auto
            background-color main-color-1
            color dark-color
            box-sizing border-box
            padding 10px
            z-index 1
        .input-wrapper-icon
          lost-column 1/1
          position relative
          img
            position absolute
            top 9px
            right 10px
            height 25px
            &:hover
              cursor pointer
          input[type=text]
            box-sizing border-box
            padding-right 46px
            padding-left 10px
            border 1px solid elements-border-color
            border-radius 20px
          input[type="text"]::-webkit-input-placeholder
            color label-text-color
            font-family opensans_regular
            font-size medium-font
      .upload-button
        lost-column 1/1
        position relative
        background-color light-color
        border 1px dashed elements-border-color
        height 40px
        line-height 40px
        border-radius 20px
        text-indent 50px
        font-size medium-font
        color label-text-color
        .photo-icon, svg
          fill label-text-color
          position absolute
          top 5px
          left 15px
      .output-container
        lost-column 1/1
        position relative
        box-sizing border-box
        padding-top 25px
        svg
          position absolute
          top 0
          right 0
        .imgItem
          lost-column 1/5 6 30px
          position relative
          img.upload-img
            width 100%
            overflow hidden
          .delete-icon
            position absolute
            display inline-block
            height 26px
            width 26px
            top -11px
            right -11px
            background-color light-color
            background-size cover
            background-repeat no-repeat
            text-align center
            border-radius 13px
            &:hover
              cursor pointer
            img
              margin-top 3px
            svg
              display inline-block
              margin-top 4px
              margin-right 4px
              g
                fill svg-icons-color






.static-content
  lost-column 1/1
  margin-bottom 40px
  .text
    a:link, a:visited, a:hover, a:active
      color dark-color
      text-decoration underline
  .phone
    img
      height 15px
      margin-right 10px
  .single-link-container
    margin-bottom 25px
    a:link, a:visited, a:hover, a:active
      color dark-color
      text-decoration underline
.agency_container
  width 100%
  margin-bottom 20px
  background-color desc-line-bg-color
  padding 5px 0
  position relative
  font-family open_sanssemibold
  line-height 27px
  &:before, &:after
    content ''
    position absolute
    width 20px
    height 100%
    background-color desc-line-bg-color
    top 0
  &:before
    left -20px
  &:after
    right -20px
  .back-and-add-container
    lost-column 1/1
    margin-bottom 20px
    margin-top 15px
    .back-link-container
      lost-column 1/3 0 0
      a
        display inline-block
        font-size medium-font-s
        font-family open_sansbold
        text-transform uppercase
        &:link, &:visited, &:hover, &:active
          color label-text-color
        .arrow
          display inline-block
          width 0
          height 0
          border-top 6px solid transparent
          border-bottom 6px solid transparent
          border-right 7px solid label-text-color
  .add-containder
    lost-column 2/3 0 0
    position relative
    .add-wrapper
      display table
      position absolute
      right 0
      .image-container
        display table-cell
        box-sizing border-box
        padding-right 7px
        padding-top 2px
        img
          width 17px
      .add-link-container
        display table-cell
        vertical-align middle
        a
          font-size medium-font-s
          font-family open_sansbold
          text-transform uppercase
          &:link, &:visited, &:hover, &:active
            color main-color-1
  .first-name, .last-name
    display inline-block
    font-size large-font
    color label-text-color
    font-family opensans_regular
  .agency-caption
    font-size medium-font-l
    font-family open_sansbold
    color label-text-color
  .agency-data
    font-size medium-font
    margin-bottom 10px
    line-height 20px
    a:link, a:visited, a:hover, a:active
      text-decoration underline
      color dark-color

.notification-container
  display table
  width 100%
  box-sizing border-box
  padding 0 20px
  .icon-container
    display table-cell
    width 25px
    height 30px
    vertical-align middle
  .notice-text
    display table-cell
    height 30px
    line-height 27px
    font-size medium-font
    color error-color
.notification-container.danger
  background-color desc-line-bg-color
  .icon-container
    svg
      .icon-body
        fill light-color
      .icon-border
        stroke error-color
      .icon-text
        fill error-color
.map-container
  height 500px
  width 100%
  margin-bottom 50px
.tempLinks-container
  lost-column 1/1
  background-color rgba(0, 0, 0, .5)
  box-sizing border-box
  padding 10px 20px
  margin-bottom 30px
  div
    a
      font-size small-font
    a:link, a:visited, a:hover, a:active
      color light-color


footer
  lost-column 1/1
  width 100%
  height 45px
  background-color black
  background #4c4c4c /* Old browsers */
  background -moz-linear-gradient(top, #4c4c4c 0%, #595959 0%, #000000 0%, #474747 0%, #111111 0%, #666666 0%, #2c2c2c 0%, #2b2b2b 24%, #1c1c1c 55%) /* FF3.6-15 */
  background -webkit-linear-gradient(top, #4c4c4c 0%,#595959 0%,#000000 0%,#474747 0%,#111111 0%,#666666 0%,#2c2c2c 0%,#2b2b2b 24%,#1c1c1c 55%) /* Chrome10-25,Safari5.1-6 */
  background linear-gradient(to bottom, #4c4c4c 0%,#595959 0%,#000000 0%,#474747 0%,#111111 0%,#666666 0%,#2c2c2c 0%,#2b2b2b 24%,#1c1c1c 55%) /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  .content
    text-align center
    a
      display inline-block
      width 30%
      height 100%
      line-height 45px
      text-transform uppercase
      font-size small-font
    a:link, a:visited, a:hover, a:active
      color light-color

