// -------------------------
// Fonts
// -------------------------

@font-face
  font-family: 'opensans_regular'
  src: url('../fonts/opensans-regular.eot')
  src: url('../fonts/opensans-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/opensans-regular.woff') format('woff'), url('../fonts/opensans-regular.ttf') format('truetype'), url('../fonts/opensans-regular.svg#open_sansregular') format('svg')
  font-weight: normal
  font-style: normal

// @font-face
//   font-family: 'opensans_light'
//   src: url('../fonts/OpenSans-Light-webfont.eot')
//   src: url('../fonts/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-Light-webfont.woff') format('woff'), url('../fonts/OpenSans-Light-webfont.ttf') format('truetype'), url('../fonts/OpenSans-Light-webfont.svg#open_sanslight') format('svg')
//   font-weight: normal
//   font-style: normal

@font-face
  font-family: 'open_sanslight'
  src: url('../fonts/OpenSans-Light-webfont.eot')
  src: url('../fonts/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-Light-webfont.woff') format('woff'), url('../fonts/OpenSans-Light-webfont.ttf') format('truetype'), url('../fonts/OpenSans-Light-webfont.svg#open_sanslight') format('svg')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'open_sansbold'
  src: url('../fonts/OpenSans-Bold-webfont.eot')
  src: url('../fonts/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-Bold-webfont.woff') format('woff'), url('../fonts/OpenSans-Bold-webfont.ttf') format('truetype'), url('../fonts/OpenSans-Bold-webfont.svg#open_sansbold') format('svg')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'open_sanssemibold'
  src: url('../fonts/OpenSans-Semibold-webfont.eot')
  src: url('../fonts/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-Semibold-webfont.woff') format('woff'), url('../fonts/OpenSans-Semibold-webfont.ttf') format('truetype'), url('../fonts/OpenSans-Semibold-webfont.svg#open_sanssemibold') format('svg')
  font-weight: normal
  font-style: normal

