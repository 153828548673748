.edd-root,
.edd-root *,
.edd-root *::before,
.edd-root *::after
    margin 0
    padding 0
    box-sizing border-box
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale

.edd-root
    display inline-block
    position relative
    width 100%
    user-select none
    font-weight 300
    font-size medium-font
    color dark-color
    &::after
        content ''
        position absolute
        bottom 0
        left 0
        right 0
        height 2px
        background brand5-color
        transition transform 150ms ease-out
        transform scaleX(0)
    &.edd-root-invalid
        &::after
            background rgb(255, 105, 105)
    &:not(.edd-root-disabled)
        .edd-head
            &:hover
                border-bottom-color brand7-color

.edd-root-disabled
    color brand6-color
    cursor not-allowed
    .edd-arrow
        color brand6-color

.edd-root.edd-root-focused::after,
.edd-root.edd-root-invalid::after
    transform scaleX(1)

.edd-head
    position relative
    overflow hidden
    border-bottom 1px solid brand6-color
    transition border-color 200ms

.edd-value
    width 100%
    display inline-block
    vertical-align middle
    padding 8px 25px 8px 0

.edd-arrow
    position absolute
    width 14px
    height 10px
    top calc(50% - 5px)
    right 3px
    transition transform 150ms
    pointer-events none
    color brand7-color
    &::before
        content ''
        position absolute
        width 8px
        height 8px
        border-right 1px solid brand3-color
        border-bottom 1px solid brand3-color
        top 0
        right 2px
        transform rotate(45deg)
        transform-origin 50% 25%

.edd-root-open
    .edd-arrow
        transform rotate(180deg)
    .edd-body
        opacity 1
        pointer-events all
        transform scale(1)
        transition opacity 200ms, transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94)

.edd-value,
.edd-option,
.edd-group-label
    white-space nowrap
    text-overflow ellipsis
    overflow hidden

.edd-root:not(.edd-root-disabled) .edd-value,
.edd-option
    cursor pointer

.edd-select
    position absolute
    opacity 0
    left -999px

.edd-root-native
    .edd-select
        left 0
        top 0
        width 100%
        height 100%

.edd-body
    opacity 0
    position absolute
    left 0
    right 0
    pointer-events none
    overflow hidden
    z-index 999
    background light-color
    box-shadow 0 0 6px rgba(0, 0, 0, 0.08)
    border 1px solid brand6-color
    border-top 0
    border-right 0

.edd-root-open-above
    .edd-body
        bottom 100%

.edd-root-open-below
    .edd-body
        top 100%

.edd-items-list
    overflow auto
    max-height 0
    transition max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94)
    -webkit-overflow-scrolling touch
    &::-webkit-scrollbar
        width 12px
    &::-webkit-scrollbar-track
        background brand6-color
    &::-webkit-scrollbar-thumb
        background brand6-color

.edd-group-label
    font-size small-font
    padding 4px 8px 4px 0
    color brand7-color
    font-weight 600

.edd-group-has-label
    padding-left 22px

.edd-option
    position relative
    padding 4px 8px 4px 22px

.edd-option-selected
    font-weight 400
    &::before
        content ''
        position absolute
        width 8px
        height 4px
        border-bottom 2px solid brand3-color
        border-left 2px solid brand3-color
        left 6px
        top calc(50% - 4px)
        transform rotate(-45deg)

.edd-option-focused
    &:not(.edd-option-disabled)
        color brand3-color

.edd-option-disabled,
.edd-group-disabled .edd-option
    cursor default
    color brand6-color

.edd-gradient-top,
.edd-gradient-bottom
    content ''
    position absolute
    left 2px
    right 12px
    height 32px
    background-image linear-gradient(
            0deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 40%,
            rgba(255, 255, 255, 1) 60%,
            rgba(255, 255, 255, 0) 100%
        )
    background-repeat repeat-x
    background-size 100% 200%
    pointer-events none
    transition opacity 100ms
    opacity 0

.edd-gradient-top
    background-position bottom
    top 0

.edd-gradient-bottom
    background-position top
    bottom 0

.edd-body-scrollable .edd-gradient-top,
.edd-body-scrollable .edd-gradient-bottom
    opacity 1

.edd-body-scrollable.edd-body-at-top .edd-gradient-top,
.edd-body-scrollable.edd-body-at-bottom .edd-gradient-bottom
    opacity 0